import React, { useState } from 'react';
import { makeStyles, createStyles, Theme, Typography } from '@material-ui/core';
import { Properties, RequestParameter } from '../../utils/model';

const CONTAINER_GUTTER = '50px';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: theme.galleryViewBackgroundColor,
      position: 'relative',
      gridArea: '1 / 1 / 2 / 3',
    },
    participantContainer: {
      position: 'absolute',
      display: 'flex',
      top: CONTAINER_GUTTER,
      right: CONTAINER_GUTTER,
      bottom: CONTAINER_GUTTER,
      left: CONTAINER_GUTTER,
      margin: '0 auto',
      alignContent: 'center',
      flexWrap: 'wrap',
      justifyContent: 'center',
    },
    buttonContainer: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    buttonContainerLeft: {
      right: `calc(100% - ${CONTAINER_GUTTER})`,
      left: 0,
    },
    buttonContainerRight: {
      right: 0,
      left: `calc(100% - ${CONTAINER_GUTTER})`,
    },
    pagination: {
      '& .MuiPaginationItem-root': {
        color: 'white',
      },
    },
    paginationButton: {
      color: 'black',
      background: 'rgba(255, 255, 255, 0.8)',
      width: '40px',
      height: '40px',
      '&:hover': {
        background: 'rgba(255, 255, 255)',
      },
    },
    paginationContainer: {
      position: 'absolute',
      top: `calc(100% - ${CONTAINER_GUTTER})`,
      right: 0,
      bottom: 0,
      left: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    title: {
      color: 'white',
    },
  })
);

export function DisconnectedView() {
  const classes = useStyles();
  const [isFirstLoad, setIsFirstLoad] = useState<boolean>(true);
  const props = Properties.getInstance();
  const rp = RequestParameter.getInstance();

  if (isFirstLoad) {
    if (rp.getCallList().length > 0) {
    	const endpoint = !window.location.origin.includes('https://twilio.v-unite.com') ? 'https://v-unite-chat.com/api/v1.test/call-log/' : 'https://v-unite-chat.com/api/v1/call-log/';
      let params = {
        call_status: 'end',
        name: rp.getName(),
        room: rp.getRoom(),
        email: rp.getEmail(),
        video: rp.getVideo(),
        calling: rp.getCalling(),
      };

      fetch(endpoint, {
        method: 'POST',
        body: JSON.stringify(params),
      }).then();
    }
    setIsFirstLoad(false);
  }

  return (
    <div className={classes.container}>
      <div className={classes.participantContainer}>
        <Typography variant="h6" className={classes.title}>
          {props.get('lastCallMessage')}
        </Typography>
      </div>
    </div>
  );
}
