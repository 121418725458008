import { useEffect, useState } from 'react';
import useVideoContext from '../useVideoContext/useVideoContext';

type RoomStateType = 'disconnected' | 'connected' | 'reconnecting'| 'initializing' | 'timeout';
let status = "";

export default function useRoomState() {
  const { room } = useVideoContext();
  const [state, setState] = useState<RoomStateType>('initializing');

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as RoomStateType);
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .off('initializing', setRoomState)
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }
  }, [room]);
  status = state;
  return state;
}

export function getRoomState() {
	return status;
}

export function setRoomState(aStatus: string) {
	status = aStatus;
}
