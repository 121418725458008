import React from 'react';
import clsx from 'clsx';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button } from '@material-ui/core';

import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { RequestParameter, Properties } from '../../../utils/model';
import { Room } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

const rp = RequestParameter.getInstance();
const props = Properties.getInstance();

const disconnect = (room: Room | null) => {
  let endpoint = props.get('baseUrl') + '/room/completed';
  fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify({
      roomName: rp.getRoom(),
    }),
  }).then();

  room!.disconnect();
};

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const { room } = useVideoContext();

  return (
    <Button onClick={() => disconnect(room)} className={clsx(classes.button, props.className)} data-cy-disconnect>
      Disconnect
    </Button>
  );
}
