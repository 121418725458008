

export class RequestParameter {
	private static instance: RequestParameter;

	private constructor() {}

	private room = "";
	private name = "";
	private email = "";
	private video = "";
	private calling = "";

	public static getInstance(): RequestParameter {
		if (!RequestParameter.instance) {
			RequestParameter.instance = new RequestParameter();
		}
		return RequestParameter.instance;
	}

	public setAll(room: string, name: string, email: string, video: string, calling: string) {
		this.room = room;
		this.name = name;
		this.email = email;
		this.video = video;
		this.calling = calling;
	}

	public getName() {
		return this.name;
	}

	public setName(name: string) {
		this.name = name;
	}

	public getRoom() {
		return this.room;
	}

	public setRoom(room: string) {
		this.room = room;
	}

	public getEmail() {
		return this.email;
	}

	public setEmail(email: string) {
		this.email = email;
	}

	public getVideo() {
		return this.video;
	}

	public setVideo(video: string) {
		this.video = video;
	}

	public getCalling() {
		return this.calling;
	}

	public setCalling(calling: string) {
		this.calling = calling;
	}

	public getCallList() {
		return this.calling.split(",");
	}

	public isOrganization() {
		return this.calling !== null && this.calling !== '';
	}
}


export class Properties {
	private static instance: Properties;

	private constructor() {}

	private data = {} as any;

	public static getInstance(): Properties {
		if (!Properties.instance) {
			Properties.instance = new Properties();
		}
		return Properties.instance;
	}

	public get(key: string) {
		return this.data[key];
	}

	public put(key: string, value: any) {
		this.data[key] = value;
	}
}
